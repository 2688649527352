let trid;

$(function () {
    var currentURL = window.location.href;

    /* GIDDY */
    if (currentURL.includes("go.getlifevac.eu") || currentURL.includes("sale.getlifevac.eu")) {
        if (currentURL.includes("offer/upsell") || currentURL.includes("offer/thank-you")) {
            addScript('https://www.gu-ecom.com/scripts/sdk/everflow.js')
                .then(() => {
                    addScript('/_lp_data/files/lifetools/lifevac/checkout/businesslogic/tracking/conversion.js').then(() => {})
                })
                .catch(error => console.error('Error loading script:', error));
        }else{
            addScript('https://www.gu-ecom.com/scripts/sdk/everflow.js')
                .then(() => {
                    addScript('/_lp_data/files/lifetools/lifevac/checkout/businesslogic/tracking/cookiescript.js').then(() => {})
                })
                .catch(error => console.error('Error loading script:', error));
        }
    }else if
        /* AWIN */
    (currentURL.includes("offer.lifevac.io")){
        if (currentURL.includes("offer/upsell") || currentURL.includes("offer/thank-you")) {
            addScript('https://www.mm0ntrk.com/scripts/sdk/everflow.js')
                .then(() => {
                    addScript('/_lp_data/files/lifetools/lifevac/checkout/businesslogic/tracking/conversion.js').then(() => {})
                })
                .catch(error => console.error('Error loading script:', error));
        }else{
            addScript('https://www.mm0ntrk.com/scripts/sdk/everflow.js')
                .then(() => {
                    addScript('https://www.dwin1.com/76342.js')
                        .then(() => {
                            addScript('/_lp_data/files/lifetools/lifevac/checkout/businesslogic/tracking/cookiescript.js').then(() => {})
                        })
                })
                .catch(error => console.error('Error loading script:', error));
        }
    }else{
        /* REST OF THE URLS */
        if (currentURL.includes("offer/upsell") || currentURL.includes("offer/thank-you")) {
            addScript('https://www.mm0ntrk.com/scripts/sdk/everflow.js')
                .then(() => {
                    addScript('/_lp_data/files/lifetools/lifevac/checkout/businesslogic/tracking/conversion.js').then(() => {})
                })
                .then(() => {})
                .catch(error => console.error('Error loading script:', error));
        }else{
            addScript('https://www.mm0ntrk.com/scripts/sdk/everflow.js')
                .then(() => {
                    addScript('/_lp_data/files/lifetools/lifevac/checkout/businesslogic/tracking/cookiescript.js').then(() => {})
                })
                .then(() => {})
                .catch(error => console.error('Error loading script:', error));
        }
    }
});

function addScript(src) {
    return new Promise((resolve, reject) => {
        var script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;

        if(src === 'https://www.dwin1.com/76342.js'){
            script.defer = true;
            document.body.appendChild(script);
        }else{
            document.head.appendChild(script);
        }
    });
}